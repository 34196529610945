import * as Sentry from '@sentry/react';
import useCookies from './useCookies';

const useSentryEvent = () => {
  const { getCookie } = useCookies();

  const sendSentryEvent = (message) => {
    try {
      Sentry.captureMessage(message, {
        extra: {
          cookies: {
            _fbp: getCookie('_fbp'),
            first_handlID: getCookie('first_handlID'),
            gclid: getCookie('gclid'),
            handlID: getCookie('handlID'),
            handlID_ref: getCookie('handlID_ref'),
            handl_ip: getCookie('handl_ip'),
            handl_url: getCookie('handl_url'),
            _fbc: getCookie('_fbc'),
          },
          userAgent: navigator.userAgent,
          drutm:
            window.HandL && typeof window.HandL.getAll === 'function'
              ? window.HandL.getAll()
              : {},
          timestamp: new Date().toISOString(),
        },
        level: 'warning',
      });
    } catch (error) {
      // Silently handle any errors
    }
  };

  return { sendSentryEvent };
};

export default useSentryEvent;
