/* eslint-disable react/prop-types */
import * as React from 'react';
import Box from '@mui/material/Box';
import {
  compareQuestions,
  getNeuroToxicityScore,
  getQuestion,
  getQuestions,
  getVisibleQuestions,
  setQuestion,
} from '../services/QuestionsService';
import { useState } from 'react';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import useVisibility from '../../hooks/useVisibility';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import axios from 'axios';
import {
  getUserData,
  getUserLeadScoreId,
  getUserNTQ,
  getUserSessionEmail,
  setCompleteRegistrationTrue,
} from '../services/AuthService';
import { getApiKey, getUrl } from '../api/agent';
import moment from 'moment/moment';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import useCookies from '../../hooks/useCookies';

// eslint-disable-next-line react/prop-types
export default function ReusableQuestionare(props) {
  const { getCookie } = useCookies();
  const [open, setOpen] = React.useState(false);
  const [score, setScore] = useState(0);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [content] = useState(props.label ? props.label : 'label');
  const questionValue = getQuestion(props.identifier);
  const [sliderValue, setSliderValue] = useState(
    questionValue !== null && questionValue !== undefined ? questionValue : null
  );

  const updateConversion = (handl_event_id) => {
    const email = getUserSessionEmail();
    const config = {
      url: getUrl() + '/completed-registration',
      method: 'post',
      data: {
        email: email,
        handl_event_id: handl_event_id,
      },
      headers: {
        'x-api-key': getApiKey(),
        'Content-Type': 'application/json',
      },
    };
    axios(config)
      .then((response) => {
        console.log(response);
        setCompleteRegistrationTrue();
      })
      .catch((error) => {
        console.error('Error:', error);
        throw error;
      });
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const handleVisibility = () => {
    if (props.isLast) {
      const event = new CustomEvent('userSawLastQuestion');
      window.dispatchEvent(event);
    }
  };

  const elementRef = useVisibility(handleVisibility);

  const handleSliderChange = async (newValue) => {
    const userData = getUserData();
    if (props.identifier === 'cold_extremities') {
      const answeredQuestions = getQuestions();
      const visibleQuestions = getVisibleQuestions();

      // compare with the "cold_extremities" question
      if (!compareQuestions(answeredQuestions, visibleQuestions, false)) {
        props.setErrorMessage('Some questions have not been answered.');
        props.setMessageType('error');
        setTimeout(() => {
          props.setErrorMessage(null);
        }, 2000);
        return;
      }

      symptomDescription();
      setQuestion(props.identifier, +newValue);
      const score = getNeuroToxicityScore();
      setScore(+score);
      handleOpen();

      // Trigger Meta/Facebook completeRegistration event
      const userData = getUserData();
      if (!userData.completeRegistration) {
        console.log('Triggering CompleteRegistration...');
        const handl_event_id = getCookie('first_handlID');
        if (handl_event_id) {
          console.log('Firing NTA event with eventID', handl_event_id);
          window.HandL.sendFBEvent(
            'NTA',
            {},
            { eventID: 'pp_nta_' + handl_event_id }
          );
        } else {
          console.log('Firing NTA event without eventID');
          window.HandL.sendFBEvent('NTA', {});
        }
        updateConversion(handl_event_id);
      }
      await updateScore(score);
    }

    // don't update unless all questions have been answered
    setSliderValue(+newValue);
    symptomDescription();
    setQuestion(props.identifier, +newValue);
  };

  const updateScore = async (score) => {
    try {
      await axios.post(
        'https://zgg7z5tys4.execute-api.us-east-2.amazonaws.com/prod/save-score',
        {
          email: getUserSessionEmail(),
          ntq_score: score,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      const ntqUserData = getUserData();
      ntqUserData.dateofBirth = moment
        .utc(ntqUserData.dateofBirth)
        .startOf('day')
        .valueOf();
      const questions = getQuestions();
      questions.ntq_id = getUserNTQ();
      questions.lead_score_id = getUserLeadScoreId();

      const ntqResponse = {
        url: getUrl() + '/edit-ntq',
        method: 'post',
        data: { ...ntqUserData, ...questions },
        headers: {
          'x-api-key': getApiKey(),
          'Content-Type': 'application/json',
        },
      };
      await axios(ntqResponse);
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const symptomDescription = () => {
    switch (sliderValue) {
      case 0:
        return 'Never had the symptom';
      case 1:
        return 'Occasionally have it, mild effect';
      case 2:
        return 'Occasionally have it, severe effect';
      case 3:
        return 'Frequently have it, mild effect';
      case 4:
        return 'Frequently have it, severe effect';
      default:
        return '';
    }
  };

  function LinearProgressWithLabel(props) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      ref={elementRef}
      sx={{
        flex: '50%',
        width: '100%',
        padding: { xs: '20px 10px', sm: '10px', md: '10px' },
        display: 'grid',
        gridTemplateColumns: { xs: '100%', md: '35% 40% 25%' },
        gridTemplateRows: '1fr',
        alignItems: 'center',
      }}
    >
      <Modal
        open={open}
        onClose={handleClose}
        keepMounted
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Neurotoxicity Assessment Result
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Score: <span style={{ fontWeight: 'bolder' }}>{score}</span>. Please
            keep this score with you.
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, fontSize: '15px' }}
          ></Typography>
          <Box sx={{ marginTop: '20px' }}>
            <LinearProgressWithLabel value={20} />
          </Box>
          <Box display="flex" justifyContent="center">
            <Button
              onClick={handleClose}
              sx={{
                marginTop: '20px',
                backgroundColor: 'rgb(133, 180, 205)',
                color: 'white',
                ':hover': {
                  backgroundColor: 'rgb(169 201 218)',
                },
              }}
            >
              Continue with Assessment
            </Button>
          </Box>
        </Box>
      </Modal>
      <Box
        className="title-questions"
        sx={{ fontSize: '14px', fontWeight: 'bolder' }}
      >
        {content}
      </Box>
      <Box>
        <RadioGroup
          onChange={(event) => handleSliderChange(event.target.value)}
          row
          value={sliderValue}
          style={{
            border: '2px solid #cbcccc',
            borderRadius: '22px',
            display: 'flex',
            alignItems: 'stretch',
          }}
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
        >
          <FormControlLabel
            style={{
              flex: '1',
              backgroundColor: sliderValue === 0 ? '#85b4cd' : 'white',
              borderRadius: '20px',
              height: '45px',
              color: sliderValue === 0 ? 'white' : 'black',
              justifyContent: 'center',
              margin: '0',
            }}
            value="0"
            control={<Radio />}
            label="0"
          />
          <FormControlLabel
            style={{
              flex: '1',
              backgroundColor: sliderValue === 1 ? '#85b4cd' : 'white',
              borderRadius: '20px',
              height: '45px',
              color: sliderValue === 1 ? 'white' : 'black',
              justifyContent: 'center',
              margin: '0',
            }}
            value="1"
            control={<Radio />}
            label="1"
          />
          <FormControlLabel
            style={{
              flex: '1',
              backgroundColor: sliderValue === 2 ? '#85b4cd' : 'white',
              borderRadius: '20px',
              height: '45px',
              color: sliderValue === 2 ? 'white' : 'black',
              justifyContent: 'center',
              margin: '0',
            }}
            value="2"
            control={<Radio />}
            label="2"
          />
          <FormControlLabel
            style={{
              flex: '1',
              backgroundColor: sliderValue === 3 ? '#85b4cd' : 'white',
              borderRadius: '22px',
              height: '45px',
              color: sliderValue === 3 ? 'white' : 'black',
              justifyContent: 'center',
              margin: '0',
            }}
            value="3"
            control={<Radio />}
            label="3"
          />
          <FormControlLabel
            style={{
              flex: '1',
              backgroundColor: sliderValue === 4 ? '#85b4cd' : 'white',
              borderRadius: '20px',
              height: '45px',
              color: sliderValue === 4 ? 'white' : 'black',
              justifyContent: 'center',
              margin: '0',
            }}
            value="4"
            control={<Radio />}
            label="4"
          />
        </RadioGroup>
      </Box>
      <Box
        className="description-questions"
        sx={{ marginLeft: '15px', fontSize: '13px' }}
      >
        {symptomDescription()}
      </Box>
    </Box>
  );
}
